import {LDictionary, LDictionaryElement} from '~/types/LDictionary';
import {LoanPurposeWhat} from '~/enums/LoanPurposeWhat';
import {MarketType} from '~/enums/MarketType';
import {LOAN_PERIOD_STEP, MAX_MORTGAGE_LOAN_PERIOD} from '~/service/constants/simulationConstants';

export enum LoanPurposeActivity {
  BUYING = 'Buying',
  BUYING_AND_FINISHING = 'BuyingAndFinishing',
  BUYING_AND_RENOVATING = 'BuyingAndRenovating',
  BUILDING = 'Building',
  RENOVATING = 'Renovating',
  ADAPTATION = 'Adaptation',
  REFINANCING = 'Refinancing',
  CONSOLIDATION = 'Consolidation'
}

export default function useDictionaries()  {
  const activitiesSimple: LDictionaryElement<LoanPurposeActivity>[] = [
    { value: LoanPurposeActivity.BUYING, name: 'Zakup', },
    { value: LoanPurposeActivity.BUILDING, name: 'Budowa', },
    { value: LoanPurposeActivity.ADAPTATION, name: 'Adaptacja', },
  ];

  const colloteralsSimple: (LDictionaryElement<LoanPurposeWhat> & {condition?: (activity: string) => boolean})[] = [
    { value: LoanPurposeWhat.APARTMENT, name: 'Mieszkanie', },
    { value: LoanPurposeWhat.HOUSE, name: 'Dom', },
    { value: LoanPurposeWhat.BUILDING_PLOT, name: 'Działka', condition: (activity: string) => ['Buying', 'Adaptation',].includes(activity), },
    { value: LoanPurposeWhat.OFFICE, name: 'Lokal użytkowy', },
  ];

  const markets: LDictionaryElement<MarketType>[] = [
    { value: MarketType.PRIMARY, name: 'Developer', },
    { value: MarketType.SECONDARY, name: 'Osoba prywatna', },
  ];

  // Generuje dictionary na podstawie MAX_LOAN_PERIOD
  const durations: LDictionary<number> = (new Array(MAX_MORTGAGE_LOAN_PERIOD / LOAN_PERIOD_STEP)).fill(0).map((x, index) => {
    const yearOption = (index + 1) * LOAN_PERIOD_STEP;
    return {
      name: `${yearOption} lat`,
      value: yearOption,
    };
  });

  return {
    markets,
    colloteralsSimple,
    activitiesSimple,
    durations,
  };
}

